import React from 'react';
import ProgressBar from './ProgressBar';

const Step3 = ({ 
  phoneNumber, 
  setPhoneNumber, 
  additionalValue, 
  setAdditionalValue, 
  handleOutaGripSave, 
  setStep, 
  message 
}) => {
  return (
    <div className="container mt-5">
      <ProgressBar step={3} />
      <h3 className="mb-4 text-center">Checkout</h3>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="mb-3">
            <label htmlFor="phoneNumberInput" className="form-label">
              Enter your phone number:
            </label>
            <input
              type="text"
              className="form-control"
              id="phoneNumberInput"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="additionalInput" className="form-label">
              Additional Input:
            </label>
            <input
              type="text"
              className="form-control"
              id="additionalInput"
              placeholder="Enter additional information"
              value={additionalValue}
              onChange={(e) => setAdditionalValue(e.target.value)}
            />
          </div>
          <div className="d-grid gap-2">
            <button className="btn btn-primary" onClick={handleOutaGripSave}>Complete Order</button>
            <button className="btn btn-secondary" onClick={() => setStep(2)}>Back to Edit</button>
          </div>
        </div>
      </div>
      {message && (
        <div className={`mt-3 alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default Step3;