import React from 'react';
import ProgressBar from './ProgressBar';

const Step1 = ({ onFileChange, handleUpload, termsChecked, handleTermsChange, message, isLoading }) => {
  return (
    <div className="container mt-5 ">
      <div className="row">
        <div className="col-md-12 text-center">
          <ProgressBar step={1} />
          <h3 className="mb-4">Upload Your Images</h3>

          <div className="mb-3">
            <label htmlFor="fileInput" className="form-label">
              Choose images to upload:
            </label>
            <input
              type="file"
              className="form-control"
              id="fileInput"
              onChange={onFileChange}
              multiple
              accept="image/*"
            />
          </div>

          <div className="mb-3">
            <input
              type="checkbox"
              id="termsCheckbox"
              checked={termsChecked}
              onChange={handleTermsChange}
            />
            <label htmlFor="termsCheckbox" style={{ marginLeft: '5px' }}>
              <span /> I accept the
              <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" style={{ marginLeft: '5px', textDecoration: 'underline' }}>
                terms and conditions
              </a>
            </label>
          </div>

          <button
            className="btn btn-primary"
            onClick={handleUpload}
            disabled={!termsChecked || isLoading}
          >
            {isLoading ? (
              <>
                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                Uploading...
              </>
            ) : (
              'Upload and Continue'
            )}
          </button>
          {message && (
            <div
              className={`mt-3 alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}
            >
              {message}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Step1;