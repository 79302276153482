import React from 'react';

const Header = () => {
  return (
    <header className="bg-dark text-white py-3">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h4 m-0">
            <a href="/" className="text-white text-decoration-none">
              <i className="bi bi-camera me-2"></i>
              OutaGrip
            </a>
          </h1>
          <nav>
            <ul className="list-unstyled d-flex m-0">
              <li className="me-3">
                <a href="/about" className="text-white text-decoration-none">About</a>
              </li>
              <li>
                <a href="/contact" className="text-white text-decoration-none">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;