import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { backendUrl } from "./config";
import 'bootstrap-icons/font/bootstrap-icons.css';
import { calculateTotalCost } from './utils/helpers';

import Header from './components/Header';
// import ProgressBar from './components/ProgressBar';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';
import Step4 from './components/Step4';

// const frameStyles = {
//     border: '10px solid #ccc',
//     padding: '10px',
//     display: 'inline-block',
// };

function App() {
    const [files, setFiles] = useState([]);
    const [catalog, setCatalog] = useState('');
    const [size, setSize] = useState('');
    const [message, setMessage] = useState('');
    const [step, setStep] = useState(1);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [editedCatalog, setEditedCatalog] = useState('');
    const [editedSize, setEditedSize] = useState('');
    const [editedCost, setEditedCost] = useState(0);
    const [editedRecommendedSize, setEditedRecommendedSize] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [additionalValue, setAdditionalValue] = useState('');
    const [totalCost, setTotalCost] = useState(0);
    const [catalogSizes, setCatalogSizes] = useState({});
    const [sizeOptions, setSizeOptions] = useState([]);
    const [termsChecked, setTermsChecked] = useState(false);
    const [recommendedSize, setRecommendedSize] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [editAllMode, setEditAllMode] = useState(false);
    const [bulkCatalog, setBulkCatalog] = useState('');
    const [bulkSize, setBulkSize] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedPreviewIndex, setSelectedPreviewIndex] = useState(null);

    // const sizeOrder = ['A0', 'A1', 'A2', 'A3', 'A3+', 'A4', 'A5', 'A6', 'A7'];
    const sizeOrder = ['A7', 'A6', 'A5', 'A4', 'A3', 'A3+', 'A2', 'A1', 'A0'];

    useEffect(() => {
        fetchCatalogSizes();
    }, []);

    useEffect(() => {
        setTotalCost(calculateTotalCost(imagePreviews));
    }, [imagePreviews]);

    const handleFileChange = useCallback((e) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 10) {
            setMessage('You can only select up to 10 files');
            return;
        }
        setFiles(selectedFiles);
        setSelectedFiles(selectedFiles);
    }, []);

    const handleUpload = useCallback(async () => {
        setIsLoading(true);
        const formData = new FormData();
        files.forEach((file) => formData.append('images', file));

        try {
            const response = await axios.post(`${backendUrl}/api/upload`, formData);
            setImagePreviews(response.data.images);
            setStep(2);
            setMessage('Images uploaded successfully');
        } catch (error) {
            console.error('Error uploading images:', error);
            setMessage('Error uploading images. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [files]);

    const handleSelectPreview = useCallback((index) => {
        setSelectedPreviewIndex(index);
        setCrop({ x: 0, y: 0 });
        setZoom(1);
    }, []);

    const handleClosePreview = useCallback(() => {
        setSelectedPreviewIndex(null);
    }, []);

    const handleCropChange = useCallback((newCrop) => {
        setCrop(newCrop);
    }, []);

    const handleZoomChange = useCallback((newZoom) => {
        setZoom(newZoom);
    }, []);

    const handleEdit = useCallback((index) => {
        const preview = imagePreviews[index];
        setEditIndex(index);
        setSelectedPreviewIndex(index);
        setEditedCatalog(preview.catalog);
        setEditedSize(preview.size);
        setEditedCost(preview.cost);
        setEditedRecommendedSize(preview.recommendedSize);
    }, [imagePreviews]);

    const handleCancel = useCallback(() => {
        setEditIndex(null);
        setSelectedPreviewIndex(null);
        setEditedCatalog('');
        setEditedSize('');
        setEditedCost(0);
        setEditedRecommendedSize('');
    }, []);

    const handleRemove = useCallback(async (index) => {
        try {
            const removedImage = imagePreviews[index];
            const fileName = removedImage.imageUrl.split('/').pop();
            await axios.delete(`${backendUrl}/api/image/remove/${encodeURIComponent(fileName)}`);

            const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
            setImagePreviews(updatedPreviews);
            setMessage('Image removed successfully');
        } catch (error) {
            console.error('Error removing image:', error);
            setMessage('Error removing image. Please try again.');
        }
    }, [imagePreviews]);

    const handleSave = useCallback(async (index) => {
        try {
            const updatedImage = {
                ...imagePreviews[index],
                catalog: editedCatalog,
                size: editedSize,
                cost: editedCost,
                recommendedSize: editedRecommendedSize,
            };

            const response = await axios.put(`${backendUrl}/api/image/save/${index}`, updatedImage);

            if (response.status === 200) {
                const updatedPreviews = [...imagePreviews];
                updatedPreviews[index] = updatedImage;
                setImagePreviews(updatedPreviews);
                setEditIndex(null);
                setMessage('Image saved successfully');
                setTotalCost(calculateTotalCost(updatedPreviews));
            } else {
                setMessage('Error saving image. Please try again.');
            }
        } catch (error) {
            console.error('Error saving image:', error);
            setMessage('Error saving image. Please try again.');
        }
    }, [imagePreviews, editedCatalog, editedSize, editedCost, editedRecommendedSize, backendUrl]);

    const handleTermsChange = () => {
        setTermsChecked(!termsChecked);
    };

    const handleCheckout = async () => {
        setStep(3);        
    };

    const handleOutaGripSave = async () => {
        setStep(4);
        try {

            // Calculate total cost
            const totalCost = imagePreviews.reduce((sum, image) => sum + image.cost, 0);

            // Prepare the data to be sent to the server
            const orderData = {
                imagePreviews,
                phoneNumber,
                additionalValue,
                totalCost
            };

            // Assuming you have an API_URL variable set up
            const response = await axios.post(`${backendUrl}/save-project`, orderData);

            if (response.status === 201) {
                setStep(4);
                setMessage('Images being processed successfully. You will get a notification for payment...');

                // Prepare email data
                const emailAttachments = imagePreviews.map((preview) => {
                    const fileName = preview.imageUrl.split('/').pop();
                    return {
                        filename: fileName,
                        path: preview.imageUrl, // Send the URL of the image
                        recommendedSize: preview.recommendedSize,
                        catalog: preview.catalog,
                    };
                });

                const emailText = `
                New order received from ${phoneNumber}
                Additional info: ${additionalValue}
                Total Cost: ${totalCost} Ugx
                
                Image Details:
                ${imagePreviews.map((preview, index) => `
                    Image ${index + 1}:
                    - Catalog: ${preview.catalog}
                    - Recommended Size: ${preview.recommendedSize}
                    - Selected Size: ${preview.size}
                    - Cost: ${preview.cost} Ugx
                `).join('\n')}
            `;

                const emailData = {
                    to: ['markphi119@gmail.com', 'outagripkreations@gmail.com'],
                    subject: 'Outagrip Kreations order',
                    // text: `New images have been saved for ${phoneNumber} with message: ${additionalValue}`,                    // Optionally include attachments if necessary
                    text: emailText,
                    attachments: emailAttachments,
                    phoneNumber,
                };

                // Send email notification
                try {
                    const emailResponse = await axios.post(`${backendUrl}/api/send-email`, emailData);
                    if (emailResponse.status === 200) {
                        console.log('Email sent successfully');
                    } else {
                        console.error('Error sending email:', emailResponse.data.error);
                    }
                } catch (emailError) {
                    console.error('Error sending email:', emailError);
                }

            } else {
                // Handle error
                console.error('Error saving project:', response.data.error);
                setMessage('Error saving project. Please try again.');
            }
        } catch (error) {
            console.error('Error saving project:', error);
            setMessage('Error saving project. Please try again.');
        }
        // setMessage('Images being processed successfully. You will get a notification for payment...');
    };

    const handleCatalogChange = async (e, index) => {
        const selectedCatalog = e.target.value;
        setCatalog(selectedCatalog);

        // Reset recommendedSize when selectedCatalog changes
        // setRecommendedSize('');

        // Set the size options based on the selected catalog
        setSizeOptions(catalogSizes[selectedCatalog] || []);

        // Get the image resolution for the specific image being edited
        // const imageResolution = imagePreviews[index]?.imageResolution;
        
        // if (!imageResolution) {
        //     console.error(`No image resolution found for index ${index}`);
        //     return;
        // }

        // try {
        //     const response = await axios.get(`${backendUrl}/api/image/recommended-size`, {
        //         params: {
        //             catalog: selectedCatalog,
        //             size: size,
        //             // imageResolution: imagePreviews.map(preview => preview.imageResolution), // Assuming imageResolution is a property in imagePreviews
        //             imageResolution: imageResolution,
        //         },
        //     });
        //     const recommendedSize = response.data.recommendedSize;
        //     console.log("*********************************", recommendedSize);
        //     console.log('Image imagePreviews:', imagePreviews.map(preview => preview.imageResolution));
        //     console.log('****************%%%%%%%%%%%%%%%***********************');

        //     // setRecommendedSize(recommendedSize);
        //     // setEditedRecommendedSize(recommendedSize);
        // } catch (error) {
        //     console.error('Error fetching recommended size:', error);
        // }
    };

    const handleSizeChange = async (e, index) => {
        const selectedSize = e.target.value;
        setSize(selectedSize);

        // Get the image resolution for the specific image being edited
        const imageResolution = imagePreviews[index]?.imageResolution;

        console.log('****************%%%%%%%%%%%%%%%***********************', imageResolution);
        console.log('****************%%%%%%%%%%%%%%%***********************', imagePreviews[index]);

        if (!imageResolution) {
            console.error(`No image resolution found for index ${index}`);
            return;
        }

        console.log('**************** handleSizeChange***********************');
        console.log('Image catalog:', catalog);
        console.log('Image size:', size);
        console.log('Image imageResolution:', imageResolution);
        // console.log('Image imagePreviews:', imagePreviews.map(preview => preview.imageResolution));
        console.log('****************%%%%%%%%%%%%%%%***********************');


        try {
            // const response = await axios.get(`${backendUrl}/api/image/recommended-size`, {
            //     params: {
            //         catalog: catalog,
            //         size: selectedSize,
            //         // imageResolution: imagePreviews.map(preview => preview.imageResolution), // Assuming imageResolution is a property in imagePreviews
            //         imageResolution: imageResolution,
            //     },
            // });

            // Update cropping parameters based on the selected size
            // Example: Update crop dimensions and position
            if (selectedSize === '8x10') {
                setCrop({ x: 0, y: 0 });
                setZoom(1);
            } else if (selectedSize === '10x12') {
                setCrop({ x: 0, y: 0 });
                setZoom(1.2);
            }

            // const recommendedSize = response.data.recommendedSize;
            // setRecommendedSize(recommendedSize);

            const recommendedSize = imagePreviews[index]?.recommendedSize;
            setRecommendedSize(recommendedSize);
            setEditedRecommendedSize(recommendedSize);

            const costResponse = await axios.get(`${backendUrl}/api/image/cost/${selectedSize}/${editedCatalog}`);
            const costForSelectedSize = costResponse.data.cost;

            // Set the edited cost based on the fetched cost
            setEditedCost(costForSelectedSize);
        } catch (error) {
            console.error('Error fetching recommended size:', error);
        }
    };

    const handleEditAll = useCallback(() => {
        setEditAllMode(!editAllMode);
        if (!editAllMode) {
            // When entering edit all mode, reset bulk selections
            setBulkCatalog('');
            setBulkSize('');
        }
    }, [editAllMode]);

    const handleBulkCatalogChange = useCallback((e) => {
        const selectedCatalog = e.target.value;
        setBulkCatalog(selectedCatalog);
        setBulkSize(''); // Reset size when catalog changes
        setSizeOptions(catalogSizes[selectedCatalog] || []);
    }, [catalogSizes]);

    const handleBulkSizeChange = useCallback((e) => {
        const selectedSize = e.target.value;
        setBulkSize(selectedSize);
    }, []);

    const handleAdditionalFileChange = useCallback((e) => {
        setFiles([...e.target.files]);
    }, []);

    const handleAdditionalUpload = useCallback(async () => {
        if (files.length === 0) {
            setMessage('Please select files to upload');
            return;
        }

        setIsLoading(true);
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('images', file);
        });

        try {
            const response = await axios.post(`${backendUrl}/api/upload`, formData);

            setImagePreviews(prevPreviews => [...prevPreviews, ...response.data.images]);
            setMessage('Additional images uploaded successfully.');
            setFiles([]); // Clear the file input
            setShowUploadModal(false);
        } catch (error) {
            console.error('Error uploading additional images:', error);
            setMessage('Error uploading additional images. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [files, backendUrl]);

    const isSizeValid = (selectedSize, recommendedSize) => {
        if (selectedSize === undefined || recommendedSize === undefined) {
            return false;
        }
        return sizeOrder.indexOf(selectedSize) <= sizeOrder.indexOf(recommendedSize);
    };

    const fetchCatalogSizes = useCallback(async () => {
        try {
            const response = await axios.get(`${backendUrl}/api/image/catalog-sizes`);
            setCatalogSizes(response.data);
        } catch (error) {
            console.error('Error fetching catalog sizes:', error);
            setMessage('Error fetching catalog sizes. Please refresh the page.');
        }
    }, []);

    const applyBulkEdit = useCallback(async () => {
        if (!bulkCatalog || !bulkSize) {
            setMessage("Please select both catalog and size for bulk edit.");
            return;
        }

        setIsLoading(true);

        try {
            const updatedPreviews = await Promise.all(imagePreviews.map(async (preview) => {
                const response = await axios.get(`${backendUrl}/api/image/recommended-size`, {
                    params: {
                        catalog: bulkCatalog,
                        size: bulkSize,
                        imageResolution: preview.imageResolution,
                    },
                });

                const costResponse = await axios.get(`${backendUrl}/api/image/cost/${bulkSize}/${bulkCatalog}`);

                return {
                    ...preview,
                    catalog: bulkCatalog,
                    size: bulkSize,
                    // recommendedSize: response.data.recommendedSize || 'N/A',
                    cost: costResponse.data.cost,
                };
            }));

            setImagePreviews(updatedPreviews);
            // const newTotalCost = updatedPreviews.reduce((acc, img) => acc + img.cost, 0);
            // setTotalCost(newTotalCost);
            setTotalCost(calculateTotalCost(updatedPreviews));
            setEditAllMode(false);
            setMessage("All images updated successfully with recommended sizes.");
        } catch (error) {
            console.error('Error applying bulk edit:', error);
            setMessage("Error updating images. Please try again.");
        } finally {
            setIsLoading(false);
        }
    }, [bulkCatalog, bulkSize, imagePreviews, backendUrl]);

    const onFileChange = (e) => {
        const files = Array.from(e.target.files);
        if (files.length > 10) { // For example, limit to 10 files
            alert('You can only select up to 10 files');
            return;
        }
        setSelectedFiles(files);
        handleFileChange(e);
    };

    return (
        <div className="App">

            <Header />

            {step === 1 && (
                <Step1
                    onFileChange={onFileChange}
                    handleUpload={handleUpload}
                    termsChecked={termsChecked}
                    handleTermsChange={handleTermsChange}
                    message={message}
                    isLoading={isLoading}
                />
            )}

            {step === 2 && (
                <Step2
                    selectedPreviewIndex={selectedPreviewIndex}
                    handleSelectPreview={handleSelectPreview}
                    handleClosePreview={handleClosePreview}
                    crop={crop}
                    zoom={zoom}
                    onCropChange={handleCropChange}
                    onZoomChange={handleZoomChange}
                    imagePreviews={imagePreviews}
                    handleCheckout={handleCheckout}
                    totalCost={totalCost}
                    handleEditAll={handleEditAll}
                    editAllMode={editAllMode}
                    bulkCatalog={bulkCatalog}
                    bulkSize={bulkSize}
                    handleBulkCatalogChange={handleBulkCatalogChange}
                    handleBulkSizeChange={handleBulkSizeChange}
                    applyBulkEdit={applyBulkEdit}
                    isSizeValid={isSizeValid}
                    catalogSizes={catalogSizes}
                    message={message}
                    isLoading={isLoading}
                    handleEdit={handleEdit}
                    handleCancel={handleCancel}
                    handleRemove={handleRemove}
                    handleSave={handleSave}
                    editIndex={editIndex}
                    editedCatalog={editedCatalog}
                    editedSize={editedSize}
                    editedCost={editedCost}
                    setEditedCatalog={setEditedCatalog}
                    setEditedSize={setEditedSize}
                    handleCatalogChange={handleCatalogChange}
                    handleSizeChange={handleSizeChange}
                    sizeOptions={sizeOptions}
                    recommendedSize={recommendedSize}
                    handleAdditionalUpload={handleAdditionalUpload}
                    showUploadModal={showUploadModal}
                    files={files}                    
                    handleAdditionalFileChange={handleAdditionalFileChange}
                    setShowUploadModal={setShowUploadModal}                  
                />
            )}

            {step === 3 && (
                <Step3
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    additionalValue={additionalValue}
                    setAdditionalValue={setAdditionalValue}
                    handleOutaGripSave={handleOutaGripSave}
                    setStep={setStep}
                    message={message}
                />
            )}

            {step === 4 && (<Step4 message={message} />)}

        </div>
    );
}

export default App;
