import React from 'react';
import ProgressBar from './ProgressBar';

const Step4 = ({ message }) => {
  return (
    <div className="container mt-5">
      <ProgressBar step={4} />
      <h3 className="mb-4 text-center">Thanks for Choosing Us :-)</h3>
      {message && (
        <div className={`mt-3 alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
          {message}
        </div>
      )}
    </div>
  );
};

export default Step4;