import React from 'react';
import { isSizeValid } from '../utils/helpers';
import ProgressBar from './ProgressBar';
import Cropper from 'react-easy-crop';
// import SizePreview from './SizePreview';

const frameStyles = {
    border: '10px solid #ccc',
    padding: '10px',
    display: 'inline-block',
};

const Step2 = ({
    selectedPreviewIndex,
    handleSelectPreview,
    handleClosePreview,
    crop,
    zoom,
    onCropChange,
    onZoomChange,
    imagePreviews,
    handleCheckout,
    totalCost,
    handleEditAll,
    editAllMode,
    bulkCatalog,
    bulkSize,
    handleBulkCatalogChange,
    handleBulkSizeChange,
    applyBulkEdit,
    isSizeValid,
    catalogSizes,
    message,
    isLoading,
    handleEdit,
    handleCancel,
    handleRemove,
    handleSave,
    editIndex,
    editedCatalog,
    editedSize,
    editedCost,
    setEditedCatalog,
    setEditedSize,
    handleCatalogChange,
    handleSizeChange,
    sizeOptions,
    recommendedSize,
    handleAdditionalUpload,
    showUploadModal,
    setShowUploadModal,
    files,
    handleAdditionalFileChange,
    selectedSize,
    setSelectedSize,
}) => {
    const renderPreviews = () => {
        let imageStyle = {
            width: '250px',
            height: '250px',
        };

        return (
            <div>
                {imagePreviews.map((preview, index) => {

                    let aspectRatio = 1; // Default aspect ratio
                    if (preview.size === '6x8') {
                        aspectRatio = 6 / 8; // Width / Height
                    } else if (preview.size === '10x12') {
                        aspectRatio = 10 / 12;
                    }
                    if (preview.orientation === 'landscape') {
                        imageStyle = {
                            width: '300px',
                            height: '200px',
                        };
                    } else if (preview.orientation === 'portrait') {
                        imageStyle = {
                            width: '200px',
                            height: '300px',
                        };
                    }

                    return (
                        <div key={index} style={{
                            ...frameStyles,
                            border: preview.recommendedSize === undefined || preview.size === undefined ? '10px solid #ccc' :
                                preview.size === preview.recommendedSize ? '10px solid green' :
                                    isSizeValid(preview.size, preview.recommendedSize) ? '10px solid #e3f054' : '10px solid #FF7065'
                        }}>
                            {/* Add a container div for the image and buttons */}
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img
                                    src={preview.imageUrl}
                                    alt={`Preview ${index + 1}`}
                                    style={imageStyle}
                                />

                                {/* <button
                                    className="btn btn-info"
                                    onClick={() => openPreview(index)}
                                    // data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                    title="Preview"
                                    data-bs-toggle="modal"
                                    data-bs-target="#exampleModal"
                                >
                                    <i className="bi bi-eye"></i>
                                    Preview
                                </button> */}
                                <div className="mt-2">
                                    {editIndex === index ? (
                                        <>
                                            <label >Choose</label>
                                            <br />
                                            <label htmlFor={`editedCatalog-${index}`} className="form-label">Material:</label>
                                            <select
                                                className="form-select"
                                                id={`editedCatalog-${index}`}
                                                value={editedCatalog}
                                                onChange={(e) => {
                                                    setEditedCatalog(e.target.value);
                                                    handleCatalogChange(e, index);
                                                }}
                                            >
                                                <option value="">Select Printing material</option>
                                                {Object.keys(catalogSizes).map((catalog, index) => (
                                                    <option key={index} value={catalog}>
                                                        {catalog}
                                                    </option>
                                                ))}
                                            </select>
                                            <br />
                                            <label htmlFor={`editedSize-${index}`} className="form-label">Size:</label>
                                            <select
                                                className="form-select"
                                                id={`editedSize-${index}`}
                                                value={editedSize}
                                                onChange={(e) => {
                                                    setEditedSize(e.target.value);
                                                    handleSizeChange(e, index);
                                                }}
                                            >
                                                <option value="">Select Size</option>
                                                {sizeOptions.map((option, index) => (
                                                    <option key={index} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </select>
                                            <br />

                                            {editedSize && ( // Only display if editedSize is selected
                                                <>
                                                    <label htmlFor={`editedRecommendedSize-${index}`} className="form-label">Recommended Size:</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id={`editedRecommendedSize-${index}`}
                                                        value={recommendedSize}
                                                        readOnly
                                                    />
                                                </>
                                            )}

                                            <label htmlFor={`editedCost-${index}`} className="form-label">Cost:</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id={`editedCost-${index}`}
                                                value={editedCost}
                                                readOnly
                                            />

                                            <button className="btn btn-primary mt-2" onClick={() => handleSave(index)}>Save</button>
                                            <button className="btn btn-secondary mt-2 ms-5" onClick={handleCancel}>Cancel</button>
                                        </>
                                    ) : (
                                        <>
                                            {preview.catalog && (
                                                <p><strong>Material:</strong> {preview.catalog}</p>
                                            )}
                                            {preview.size && (
                                                <p><strong>Size:</strong> {preview.size}</p>
                                            )}
                                            {preview.recommendedSize && ( // Only display if recommendedSize is set
                                                <p><strong>Recommended Size:</strong> {preview.recommendedSize}</p>
                                            )}
                                            {/* <p><strong>Recommended Size:</strong> {preview.recommendedSize ?? ''}</p> */}
                                            {preview.cost && (
                                                <p><strong>Cost:</strong> {preview.cost}</p>
                                            )}
                                            <button
                                                className="btn btn-primary me-2"
                                                onClick={() => handleEdit(index)}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Edit"
                                            >
                                                {/* <i className="bi bi-pencil-square"></i> */}
                                                <i class="bi bi-eyedropper"></i>
                                                Choose Material
                                            </button>
                                            <button
                                                className="btn btn-danger "
                                                onClick={() => handleRemove(index)}
                                                data-bs-toggle="tooltip"
                                                data-bs-placement="top"
                                                title="Remove"
                                            >
                                                <i className="bi bi-trash"></i>
                                                Remove
                                            </button>
                                        </>
                                    )}
                                </div>
                            </div>
                            {selectedPreviewIndex !== null && (
                                // console.log('***************inside dialog****************************'),
                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Preview2</h5>
                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                            </div>
                                            <div class="modal-body" style={{ height: '400px' }}>
                                                <Cropper
                                                    image={imagePreviews[selectedPreviewIndex].imageUrl}
                                                    crop={crop}
                                                    zoom={zoom}
                                                    aspect={aspectRatio}
                                                    onCropChange={onCropChange}
                                                    onZoomChange={onZoomChange}
                                                // style={{ ...imageStyle, height: '400px' }}
                                                />
                                            </div>
                                            {/* <div class="modal-footer">
                                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button type="button" class="btn btn-primary">Save changes</button>
                                </div> */}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    )
                })}
            </div>

        )
    };

    return (
        <div className="container mt-5">
            <ProgressBar step={2} />
            {isLoading && (
                <div className="position-fixed top-0 start-0 w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1050 }}>
                    <div className="spinner-border text-light" role="status" style={{ width: '3rem', height: '3rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}

            <h2 className="mb-4 text-center">Edit and Review Your Images</h2>
            <div className="mt-3">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-auto">
                            <p className="mb-0"><strong>Total Cost:</strong> {totalCost} Ugx</p>
                        </div>
                        <div className="col-auto">
                            <button
                                className="btn btn-primary"
                                onClick={handleCheckout}
                                disabled={
                                    imagePreviews.length === 0 ||
                                    totalCost === 0 ||
                                    imagePreviews.some(
                                        preview => !isSizeValid(preview.size, preview.recommendedSize)
                                    )
                                }
                            >
                                Checkout
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="mt-3 text-center">
                <button
                    className="btn btn-secondary mt-3"
                    onClick={handleEditAll}
                    disabled={imagePreviews.length === 0}
                >
                    {editAllMode ? "Cancel Edit All" : "Edit All Images"}
                </button>
            </div>

            {editAllMode && (
                <div className="mt-3 p-3 border rounded">
                    <h4>Bulk Edit</h4>
                    <div className="row">
                        <div className="col-md-4 mb-2">
                            <select
                                className="form-select"
                                value={bulkCatalog}
                                onChange={handleBulkCatalogChange}
                            >
                                <option value="">Select Catalog</option>
                                {Object.keys(catalogSizes).map((catalog, index) => (
                                    <option key={index} value={catalog}>{catalog}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4 mb-2">
                            <select
                                className="form-select"
                                value={bulkSize}
                                onChange={handleBulkSizeChange}
                                disabled={!bulkCatalog}
                            >
                                <option value="">Select Size</option>
                                {catalogSizes[bulkCatalog]?.map((size, index) => (
                                    <option key={index} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                        <div className="col-md-4 mb-2">
                            <button
                                className="btn btn-primary w-100"
                                onClick={applyBulkEdit}
                                disabled={!bulkCatalog || !bulkSize}
                            >
                                Apply to All Images
                            </button>
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-3">
                {message && (
                    <div className={`mt-3 alert ${message.includes('Error') ? 'alert-danger' : 'alert-success'}`}>
                        {message}
                        {(() => {
                            const unfitImages = imagePreviews.filter(preview => preview.size !== (preview.recommendedSize || 0));

                            if (unfitImages.length > 0) {
                                return (
                                    <>
                                        <p>{unfitImages.length} {unfitImages.length === 1 ? 'image does' : 'images do'} not match the recommended size:</p>
                                        <ul>
                                            {unfitImages.map((img, index) => (
                                                <li key={index}>Image {index + 1}: Current size {img.size}, Recommended size {img.recommendedSize}</li>
                                            ))}
                                        </ul>
                                        <p>Please edit these images to submit.</p>
                                    </>
                                );
                            }
                        })()}
                    </div>
                )}
            </div>

            <div className="position-relative mb-3">
                <button
                    className="btn btn-sm btn-primary position-absolute top-0 end-0"
                    style={{ zIndex: 1 }}
                    onClick={() => setShowUploadModal(true)}
                >
                    <i className="bi bi-plus-lg"></i>
                </button>
            </div>

            <br />
            <div className="mb-3">
                {renderPreviews()}
            </div>

            {showUploadModal && (
                <div className="modal d-block" tabIndex="-1" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Upload Additional Images</h5>
                                <button type="button" className="btn-close" onClick={() => setShowUploadModal(false)}></button>
                            </div>
                            <div className="modal-body">
                                <label htmlFor="fileInput" className="form-label">Select multiple images:</label>
                                <input
                                    type="file"
                                    className="form-control"
                                    id="fileInput"
                                    onChange={handleAdditionalFileChange}
                                    multiple
                                    accept="image/*"
                                    capture="environment"
                                />
                                <small className="form-text text-muted">
                                    You can select multiple images at once.
                                </small>
                                {files.length > 0 && (
                                    <div className="mt-3">
                                        <p>Selected {files.length} file(s):</p>
                                        <ul className="list-group">
                                            {Array.from(files).map((file, index) => (
                                                <li key={index} className="list-group-item">
                                                    {file.name} - {(file.size / (1024 * 1024)).toFixed(2)} MB
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => setShowUploadModal(false)}>Close</button>
                                <button type="button" className="btn btn-primary" onClick={handleAdditionalUpload} disabled={files.length === 0}>
                                    Upload {files.length} {files.length === 1 ? 'Image' : 'Images'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Step2;