import React from 'react';

const ProgressBar = ({ step }) => {
  const getProgressBarColor = (currentStep) => {
    switch (currentStep) {
      case 1: return '#87CEFA'; // light blue
      case 2: return '#E6E6FA'; // lighter purple
      case 3: return '#FFA07A'; // light orange
      case 4: return '#90EE90'; // light green
      default: return '#e9ecef'; // default gray
    }
  };

  const progressPercentage = (step / 4) * 100;

  return (
    <div className="progress mb-4" style={{ height: '25px' }}>
      <div
        className="progress-bar"
        role="progressbar"
        style={{
          width: `${progressPercentage}%`,
          backgroundColor: getProgressBarColor(step),
          color: 'black',
          fontSize: '1rem',
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        aria-valuenow={progressPercentage}
        aria-valuemin="0"
        aria-valuemax="100"
      >
        Step {step} of 4
      </div>
    </div>
  );
};

export default ProgressBar;